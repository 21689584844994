import { Component } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-invalidate-token',
  templateUrl: './invalidate-token.component.html',
  styleUrls: ['./invalidate-token.component.scss'],
  standalone: false,
})
export class InvalidateTokenComponent {

  constructor(authService: AuthService) {
    authService.removeAuthToken();
    window.location.href = `/login`;
  }
}
