import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from './dashboard/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { EmailVerifiedGuard } from './auth/guards/email-verified.guard';
import { AccountVerificationComponent } from './shared/component/account-verification/account-verification.component';
import { ErrorComponent } from './pages/error/error.component';

 
const routes: Routes = [
  // Root dashboard layout with lazy loading for the dashboard module
  {
    path: '',
    component: DashboardLayoutComponent,
    data: { showHeader: false, testRunnerHeader: false, dashboard: true, showFooter: false },
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  // Account verification route
  {
    path: 'verification/:token',
    canActivate: [AuthGuard, EmailVerifiedGuard],
    component: AccountVerificationComponent,
  },
  // Settings, Admin, Student, Academy routes grouped under dashboard layout
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [
      {
        path: 'settings',
        data: { showHeader: false, testRunnerHeader: false, dashboard: true, showFooter: false },
        title: 'Settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
      },

      {
        path: 'chatbot',
        data: {
          showHeader: false,
          testRunnerHeader: false,
          dashboard: true,
          showFooter: false,
        },
        title: 'Chatbot',
        loadChildren: () => import('./aibot/aibot.module').then(m => m.AibotModule) 
      },

      
      {
        path: 'admin',
        data: { showHeader: false, testRunnerHeader: false, dashboard: true, showFooter: false },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'student',
        data: { showHeader: false, testRunnerHeader: false, dashboard: true, showFooter: false },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'academy',
        data: { showHeader: false, testRunnerHeader: false, dashboard: true, showFooter: false },
        loadChildren: () =>
          import('./academy/academy.module').then((m) => m.AcademyModule),
      },
      // Online Service route under dashboard layout
      {
        path: 'online',
        data: { showHeader: false, testRunnerHeader: false, dashboard: true, showFooter: false },
        loadChildren: () =>
          import('./online-service/online-service.module').then((m) => m.OnlineServiceModule),
      },
    ],
  },

  // IELTS route
  {
    path: 'ielts',
    data: { showHeader: false, testRunnerHeader: true, dashboard: true, showFooter: false },
    loadChildren: () =>
      import('./ielts/ielts.module').then((m) => m.IeltsModule),
  },
  // Test Runner Module
  {
    path: 'celpip',
    data: { showHeader: false, testRunnerHeader: true, showFooter: false },
    loadChildren: () =>
      import('./test-runner/test-runner.module').then((m) => m.TestRunnerModule),
  },
  // Course Runner Module
  {
    path: 'course',
    data: { showHeader: false, testRunnerHeader: true, showFooter: true },
    loadChildren: () =>
      import('./course-runner/course-runner.module').then((m) => m.CourseRunnerModule),
  },
  // Pages module with general routes
  {
    path: '',
    data: { showHeader: true },
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  // Error page
  {
    path: '404',
    component: ErrorComponent,
  },
  // Wildcard route for 404 page

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
