import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { environment } from 'src/environments/environment';
import { Notify } from './shared/services/notification.service';
import { AudioService } from './test-runner/audio.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  showHeader = false;
  showSidebar = false;
  showFooter = false;
  testRunnerHeader = false;
  apiUrl = environment.api;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private notify: Notify,
    private audioService: AudioService
  ) {
    this.handleQueryParams();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.updateRouteData(event);
      }

      if (event instanceof NavigationEnd) {
        this.handleNavigationEnd(event);

        if (this.auth.check) {
          this.auth.settings(event.url);
          this.auth.loadModuleChanger();
        }
        if (event.url !== '/' && event.url.indexOf("ielts/") === -1) {
          this.audioService.stopAll();
        }
      }
    });
  }

  private handleQueryParams(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.module) {
        localStorage.setItem('module', params.module);
      }
      if (params.redirect_uri) {
        localStorage.setItem('intendedUrl', params.redirect_uri);
        localStorage.setItem('module', params.redirect_uri.includes('ielts') ? 'ielts' : 'celpip');
      }
    });
  }

  private updateRouteData(event: ActivationEnd): void {
    const routeData: any = event.snapshot.firstChild?.data || {};
    this.showHeader = routeData.showHeader !== false;
    this.showSidebar = routeData.showSidebar !== false;
    this.showFooter = routeData.showFooter !== false;
    this.testRunnerHeader = routeData.testRunnerHeader !== false;
  }

  private handleNavigationEnd(event: NavigationEnd): void {
    window.scrollTo(0, 0);

    if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
      document.body.classList.toggle('sb-sidenav-toggled');
    }
    if (
      this.auth.check &&
      event.url !== '/my-account' &&
      !this.auth.user.hasMobileNumber &&
      !event.url.includes('auth/google/redirect')
    ) {
      this.notify.error('Please fill in your mobile number to continue');
      this.router.navigateByUrl('/my-account');
    }
  }
}
