<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-6 login d-sm-none d-md-block">
      <img
        [src]="apiUrl + 'assets/images/login.png'"
        class="w-100"
        alt=""
        srcset=""
      />
      <div class="login-heading">
        <h2>Unlock your Dreams</h2>
      </div>
    </div>

    <div class="col-md-1"></div>
    <div class="col-md-12 col-lg-4 mt-5 px-4 py-2 rounded left-pageHeading">
      <div class="welcom">
        <h1 class="mb-1">Welcome to Allmock</h1>
        <p>Log in to start your journey with us.</p>
      </div>

      <p *ngIf="error" class="text-danger text-start">{{ error }}</p>
      <!-- <form [action]="apiUrl+'login'" method="post" id="appLogin">
        <input type="text" name="email" value="student@learners.fun">
        <input type="text" name="password" value="Learners@fun">
      </form> -->
      <form
        class="login-form"
        [action]="apiUrl + 'login?redirect_uri=' + redirectUri"
        method="post"
        id="appLogin"
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit(loginForm.value)"
      >
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Email Id</mat-label>
          <input
            matInput
            placeholder="Enter email id to login"
            name="email"
            formControlName="email"
          />
        </mat-form-field>
        <p class="text-right">
          <a routerLink="/forgot-password" class="col text-end"
            >Forgot your password?</a
          >
        </p>
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            name="password"
            placeholder="Enter password"
            formControlName="password"
          />
          <button
            mat-icon-button
            type="button"
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <i class="fas" [class]="hide ? 'fa-eye-slash' : 'fa-eye'"></i>
          </button>
        </mat-form-field>

        <button
          mat-raised-button
          type="submit"
          class="w-100 mb-2"
          color="primary"
        >
          <div class="d-flex align-items-center">
            <span> Login </span>
            @if (loading) {
            <mat-spinner
              color="accent"
              class="float-end ms-1 mt-1"
              [style]="{ width: '25px', height: '25px' }"
            >
            </mat-spinner>
            }
          </div>
        </button>
      </form>

      <div class="row justify-content-md-center login-bottom">
        <div class="col-md-12 mt-4 py-3 rounded bg-white login-bottom_wrapper">
          <p class="daccnt">
            Don’t have account?
            <a
              [href]="'/signup?redirect_uri=' + redirectUri"
              class="col text-start"
              >Sign up for a FREE trial now!</a
            >
          </p>
          <h4>Or sign in with</h4>
          <div class="row justify-content-md-center">
            <button
              mat-fab
              (click)="socialLogin()"
              matTooltip="Primary"
              color="transparent"
              aria-label="Example mini fab with a heart icon"
            >
              <img
                src="assets/img/Google__G__Logo.svg.webp"
                width="30"
                alt=""
                srcset=""
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
