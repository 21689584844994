import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class RedirectIfAuthenticated {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot) {
    const _isValid = this.authService.isLoggedIn();
    if (next.queryParams['invalidate'] == 'true') {
      this.authService.removeAuthToken();
    }
    if (_isValid) {
      this.router.navigate(['/dashboard']);
    }
    return true;
  }
}
