<div class="container-fluid p-0">
  <div class="row">
    <div class="col-md-6 forgot">
      <img
        [src]="apiUrl + 'assets/images/login.png'"
        class="w-100"
        alt=""
        srcset=""
      />
      <div class="forgot-heading">
        <h2>Unlock your Dreams</h2>
      </div>
    </div>
    <div class="col-md-1"></div>
    <div
      class="col-md-12 col-lg-4 mt-5 px-4 py-2 rounded bg-white left-pageHeading"
    >
      <h1 class="m-0">Forgot password</h1>
      <p>to allmock forgot password</p>
      <p *ngIf="error" class="text-danger text-start fs-6">{{ error }}</p>
      <p *ngIf="message" class="text-success text-star fs-6">{{ message }}</p>
      <form
        class="login-form"
        [formGroup]="loginForm"
        (ngSubmit)="onSubmit(loginForm.value)"
      >
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Email Id</mat-label>
          <input
            matInput
            placeholder="Enter email id to send the password reset link."
            formControlName="email"
          />
        </mat-form-field>

        <button
          mat-raised-button
          [disabled]="loading"
          type="submit"
          class="w-100 mb-2"
          color="primary"
        >
          <small> Send password reset link</small>
          <mat-spinner
            color="accent"
            *ngIf="loading"
            class="float-end ms-1 mt-1"
            [style]="{ width: '15px', height: '15px' }"
          >
          </mat-spinner>
        </button>
      </form>
      <div class="row justify-content-md-center login-bottom">
        <div class="col-md-12 mt-4 py-3 rounded bg-white login-bottom_wrapper">
          <p class="daccnt">
            Do you know password?
            <a routerLink="/login" class="col text-start">Login now</a>
          </p>
          <h4>Or sign in with</h4>
          <div class="row justify-content-md-center">
            <button
              mat-fab
              (click)="socialLogin()"
              matTooltip="Primary"
              color="transparent"
              aria-label="Example mini fab with a heart icon"
            >
              <img
                src="assets/img/Google__G__Logo.svg.webp"
                width="30"
                alt=""
                srcset=""
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
