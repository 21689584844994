import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { AuthService } from './../../auth/auth.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-test-runner-header',
  templateUrl: './test-runner-header.component.html',
  styleUrls: ['./test-runner-header.component.scss'],
  standalone: false
})
export class TestRunnerHeaderComponent {
  apiUrl: string = environment.api;
  constructor(public auth: AuthService, private router: Router) {
    if (router.url.indexOf('course') != - -1) {
      document.body.classList.add("test-runner")
    }
  }

  logout(): void {
    this.auth.logout();
  }

}
