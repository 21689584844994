import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, isDevMode, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MyAccountService } from 'src/app/services/myaccount.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Notify } from 'src/app/shared/services/notification.service';
import countries from '../../countries.json';

@Component({
  selector: 'app-social-callback',
  templateUrl: './social-callback.component.html',
  styleUrls: ['./social-callback.component.css'],
  standalone: false,
})
export class SocialCallbackComponent implements OnInit {
  user: any;
  loading: boolean = false;
  errors: any;
  countries: any[] = countries;
  public loginForm: FormGroup;
  activatedRouteSubscription: Subscription = new Subscription();
  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    public router: Router,
    private fb: FormBuilder,
    public notify: Notify,
    private myAccountService: MyAccountService
  ) {
    this.loginForm = fb.group({
      mobileNumber: new FormControl(),
      dialCode: new FormControl('+91'),
      country: new FormControl('India'),
      name: new FormControl(''),
      email: new FormControl(''),
    });
    this.activatedRouteSubscription = activatedRoute.queryParams.subscribe(
      (params) => {
        auth.setSession({
          token: params['token'],
          user: {
            name: params['name'],
          },
          expiresIn: params['expiresIn'],
        });
      }
    );

  }
  countryChange(event: any) {
    const filterDialCode = countries.filter((country: any) => {
      return country.name == event.value;
    });
    if (filterDialCode.length) {
      this.loginForm.patchValue({
        dialCode: filterDialCode[0]['dial_code'],
      });
    }
  }

  ngOnInit() {
    this.getSettings()
  }

  getSettings() {
    this.auth.getSettings().subscribe((res: any) => {
      this.user = res.data;
      this.loginForm.patchValue({
        mobileNumber: this.user.mobileNumber,
        name: this.user.name,
        email: this.user.email
      });
      localStorage.setItem('user', JSON.stringify(res.data));
      if (this.user.mobileNumber) {
        setTimeout(() => {
          const redirectUrl = sessionStorage.getItem("redirect") || "/dashboard";
          window.location.href = redirectUrl;
          sessionStorage.setItem("redirect", "")
        }, 200);
      }
    });
  }
  onSubmit(value: any) {
    value.module = this.auth.module;
    value.type = 'personal_info';
    if (!this.loginForm.valid) {
      return;
    }
    this.loading = true;
    this.myAccountService.post(value).subscribe({
      next: () => {
        this.loading = false;
        this.notify.success(
          'Congratulations! Your account has been created. Start exploring our platform now.'
        );
        if (!isDevMode()) {
          this.prepareAndSendToHubSpot({ ...value, ...this.user });
        }
        this.getSettings();
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        if (err.status == 422) {
          this.notify.error('Fill the valid mobile number');
        }
      }
    });
  }
  prepareAndSendToHubSpot(value: any) {
    const data = {
      fields: [
        {
          name: 'firstname',
          value: value.name,
        },
        {
          name: 'email',
          value: value.email,
        },
        {
          name: 'phone',
          value: value.dialCode + value.mobileNumber,
        },
        {
          name: 'country',
          value: value.country,
        },
        {
          name: 'source',
          value: 'google signup',
        },
      ],
    };

    this.auth.signup_data(data);
  }
}
