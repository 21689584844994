import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Injectable()
export class EmailVerifiedGuard {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isVerified) {
      this.router.navigate(['/']);
    }
    return true;
  }
}
