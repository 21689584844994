<!-- <nav class="navbar bg-info" *ngIf="auth.check && !auth.isVerified">
  <div class="container text-center">
    <p class="mx-auto m-0" href="#">
      Your account is not yet verified, to continue with learners.fun please check your email to verify the account. 
      <button [disabled]="loading"  href="javascript:void(0)" (click)="resendVerificationEmail()" class="btn">
        <span *ngIf="!loading">
          Resend email
        </span>
        <span *ngIf="loading">
          Resending...
        </span>
      </button>
    </p>
  </div>
</nav> -->
<div class="header-placeholder"></div>
<header id="site-header" class="fixed-top nav-fixed">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" [href]="homeUrl">
        <img
          class="img-fluid d-none d-lg-block"
          [src]="apiUrl + 'assets/images/logo.svg'"
          alt=" "
        />
        <img
          class="img-fluid d-block d-lg-none d-sm-block"
          [src]="apiUrl + 'assets/images/logo-white.svg'"
          alt=" "
        />
      </a>
      <button
        class="navbar-toggler collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
        <span class="navbar-toggler-icon fa icon-close fa-times"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        
        <ul class="navbar-nav ml-lg-auto">
          <a
                class="nav-link"
                href="chatbot"
                >Chatbot</a
              >
          <ng-container *ngFor="let menu of topMenus">
            <li class="nav-item" *ngIf="!menu.items.length">
              <a
                class="nav-link"
                [class]="menu.class"
                [href]="getUrl(menu.url)"
                >{{ menu.label }}</a
              >
            </li>

            <li *ngIf="menu.items.length" class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle hide-down-arrow"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ menu.label }} <img src="assets/img/down-arrow.png" alt="" />
              </a>
              <ul class="dropdown-menu">
                <li *ngFor="let subMenu of menu.items">
                  <a
                    class="dropdown-item"
                    [routerLinkActive]="'active'"
                    [href]="subMenu.url"
                    *ngIf="!subMenu.type"
                  >
                    <i [class]="subMenu.icon"></i> {{ subMenu.label }}
                  </a>
                  <a
                    class="dropdown-item"
                    [routerLinkActive]="'active'"
                    [href]="subMenu.url"
                    (click)="logout()"
                    *ngIf="subMenu.type === 'logout'"
                  >
                    <i [class]="subMenu.icon"></i> {{ subMenu.label }}
                  </a>
                </li>
              </ul>
            </li>
          </ng-container>
          <!--           
          <li class="nav-item" *ngIf="auth.isAdmin || auth.isCordinator">
            <a class="nav-link" [routerLinkActive]="'active'" aria-current="page" routerLink="/admin/packages">Packages</a>
          </li>
          <li class="nav-item" *ngIf="auth.isAdmin || auth.isCordinator">
            <a class="nav-link" [routerLinkActive]="'active'" aria-current="page" routerLink="/admin/mock-test">Mock test</a>
          </li>
          <li class="nav-item" *ngIf="auth.isAdmin || auth.isCordinator">
            <a class="nav-link" [routerLinkActive]="'active'" aria-current="page" routerLink="/admin/cources">Cources</a>
          </li> -->
        </ul>
      </div>
    </nav>
  </div>
</header>
