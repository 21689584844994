import { AuthService } from 'src/app/auth/auth.service';
import { environment } from './../../../environments/environment';
import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: false,
})
export class FooterComponent {
  apiUrl: string = environment.api;
  topMenus: any[];

  constructor(public auth: AuthService) {
    auth.menus.subscribe({
      next: (res: any) => {
        this.topMenus = res.topMenus?.filter((menu: any) => menu.inFooter === true);
      }
    })
  }
}
